import React from "react";
import DataProvider from '../DataProvider';
import { empty } from "../helpers/functions";
import MyLoader from '../helpers/MyLoader';
import Enevelop from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Dialog, DialogTitle, DialogActions, IconButton } from "@mui/material";
import { NotificationManager } from 'react-notifications';

const Messages = (props) => {
  const [messages, setMessages] = React.useState(props.messages);
  const [loading, setLoading] = React.useState(false);
  const [openPopup, togglePopup] = React.useState(false);

  const getUnreadMessageCount = (messages) => {
    let count = 0;
    Object.keys(messages).map((key, ctr) => {
      if(messages[key].read !== '1') count++;
    });
    return count;
  }

  const markRead = (msgId) => {
    setLoading(true);
    DataProvider('CUSTOM', 'boss-app/markMessageRead', {
      method:'POST',
      data: {
        msg_id: msgId,
        aftale_id: props.id
      }
    }).then(({data}) => {
      setMessages(data.messages);
      setLoading(false);
      NotificationManager.success('Beskeden er blevet markeret som læst.');
    }).catch(error => {
      NotificationManager.error('Noget gik galt.');
      setLoading(false);
    });
  }

  return <>
    {loading ? <MyLoader /> : null}

    {!empty(messages) ? <>
    <div className="position-relative">
        <button className="btn btn-link" onClick={()=>{togglePopup(true);}}><Enevelop className="text-info" /></button>                      
        <span className={`position-absolute bg-` + (getUnreadMessageCount(messages) > 0 ? `danger` : `dark`) + ` rounded-circle text-white`} style={{fontSize:'10px',top:'5px',left:'25px',padding:'0 5px'}}>{getUnreadMessageCount(messages) > 0 ? getUnreadMessageCount(messages) : Object.keys(messages).length}</span>                      
    </div>
    </> : <button className="btn btn-link"><Enevelop className="text-muted" /></button>}

    
    <Dialog fullWidth={true} onClose={() => togglePopup(false)} open={openPopup}>
      <DialogTitle onClose={() => togglePopup(false)}>
        Beskeder
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }} 
          onClick={() => togglePopup(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>                
      <DialogContent dividers>
        {Object.keys(messages).map((key, ctr) => (
          <div className="alert alert-secondary p-2">
            <div className="row">
              <div className="col-sm-9">
                <h5>Besked fra {(key === 'besked_fra_kundeservice' ? 'kundeservice' : (key === 'besked_fra_booking' ? 'bookingperson' : (key === 'besked_fra_ordre' ? 'bestiller' : 'sælger')))}</h5>
              </div>
              <div className="col-sm-3 text-end">
              {messages[key].read === '1' ? null : <>
                <button className="btn btn-primary btn-sm pull-right" onClick={() => markRead(messages[key].id)}>Mærke læst</button>
              </>}
              </div>
            </div>
            <p>{messages[key].message}</p>
          </div>
        ))}
      </DialogContent>
      <DialogActions dividers>
          <button className="btn btn-secondary" onClick={() => togglePopup(false)}>Luk</button>
      </DialogActions>
    </Dialog>
  </>
}

export default Messages;
