import React from 'react';
import {Dialog, DialogContent,CircularProgress,Typography}  from "@mui/material";;

export default function MyLoader(props) {
    return (
        <Dialog open={true}  disableEscapeKeyDown>
            <DialogContent style={{'textAlign':'center'}}>
                <CircularProgress disableShrink />
                    <br /><br />
                <Typography>{props.message !== undefined ? props.message : 'Please wait...'}</Typography>
            </DialogContent>
        </Dialog>
    );

}