import React from "react";
import DataProvider from '../DataProvider';
import { empty } from "../helpers/functions";
import MyLoader from '../helpers/MyLoader';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Dialog, DialogTitle, DialogActions, IconButton } from "@mui/material";
import { NotificationManager } from 'react-notifications';
import { Buffer } from "buffer";

const SendMessage = (props) => {
  const caseData = props.data;
  const caseReferences = props.data.references[0];

  const [loading, setLoading] = React.useState(false);
  const [openPopup, togglePopup] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [inputValues, setInputValues] = React.useState({phone:'',message:'',template:0,minutes:0});
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);

  const openForm = () => {
    setLoading(true);
    DataProvider('CUSTOM', 'boss-app/getSMSTemplates', {
      method:'GET'
    }).then(({data}) => {
      setLoading(false);
      setTemplates(data.content);
      
      //set default data
      data.content && data.content.map(template => {
        if(template.iDefault === '1'){
          setInputValues({...inputValues, template:template.id, message: template.szText})
        }        
      });

      // set phone numbers
      let numbers = [];
      if(!empty(caseReferences.seller.use_booking_person) && !empty(caseReferences.seller.booking_person_telefon)){
        numbers.push({name:'Bookingperson <'+caseReferences.seller.booking_person_telefon+'>', value:caseReferences.seller.booking_person_telefon, type:'Seller'});
      } else if(!empty(caseReferences.seller.kontakt_telefon)){
        numbers.push({name:'Sælger <'+caseReferences.seller.kontakt_telefon+'>', value:caseReferences.seller.kontakt_telefon, type:'Seller'});
      }

      if(!empty(caseReferences.realtor_shop.Telefon)){
        numbers.push({name:'Ejendomsmægler butik <'+caseReferences.realtor_shop.Telefon+'>', value:caseReferences.realtor_shop.Telefon, type:'Realtor shop'});
      }

      if(!empty(caseReferences.realtor_person.Telefon)){
        numbers.push({name:'Ejendomsmægler person <'+caseReferences.realtor_person.Telefon+'>', value:caseReferences.realtor_person.Telefon, type:'Realtor person'});
      }
      setPhoneNumbers(numbers);
      togglePopup(true);
      
    }).catch(error => {
      NotificationManager.error(error.message, "Error");
      setLoading(false);
    });
  }

  const setFormInput = (e) => {
    let formValues = {...inputValues};
    formValues[e.target.name] = e.target.value;

    // check for minutes
    if(e.target.name === 'minutes'){
      let message = formValues.message;

      let date1 = new Date();
      let date2 = new Date();
      date2.setMinutes(date1.getMinutes() + Number(e.target.value));
      formValues['message'] = message.replace("??",('0'+date2.getHours().toString()).slice(-2)+":"+('0'+date2.getMinutes().toString()).slice(-2));
    }

    // check for template change
    if(e.target.name === 'template'){
      let template = parseInt(e.target.value);
      if(template > 0){
        templates.map(row => {
          if(row.id == template){
            formValues['message'] = row.szText;
          }
        })
      } else {
        formValues['message'] = '';
      }
    }

    // set number
    if(e.target.name === 'numbers'){
      if(!empty(e.target.value)){
        if(e.target.value !== 'ANDET') {
          formValues['phone'] = e.target.value;
          phoneNumbers.map(phone => {
            if(phone.value === e.target.value){
              templates.map(template => {
                if(phone.type === template.szDefaultReceiver){
                  formValues['template'] = template.id;
                  formValues['message'] = template.szText;
                }
              })              
            }
          })
        } else {
          formValues['phone'] = '';
        }
      }
    }

    setInputValues(formValues);
  }

  const handleSubmit = () => {
    let validated = true;
    let phone = inputValues.phone;
    phone = phone.replace(/\s/g, "").replace(/-/g, "");

    if(empty(phone)){
      validated = false;
      NotificationManager.error("Indtast venligst telefonnummeret.");
    } else if( !(phone.match('[0-9]{8}')) ) {
      validated = false;
      NotificationManager.error('Angiv venligst et gyldigt telefonnummer');
    }

    if(empty(inputValues.message)){
      validated = false;
      NotificationManager.error("Indtast venligst SMS-tekst.");
    } else if(inputValues.message.indexOf('??') !== -1) {
      validated = false;
      NotificationManager.error("Skal du udskifte ?? fra SMS-tekst.");
    }

    if(!validated) return;

    const encodedMessage = Buffer.from(inputValues.message).toString('base64');
    setLoading(true);
    DataProvider('CUSTOM', 'boss-field/sendSMS', {
      method:'POST',
      data: {
        from_pwa: true,
        szMobile: '919718238798', //phone,
        szMessage: encodedMessage,
        szCase: caseReferences.ref,
        szAddress: caseData.address,
        HENR: localStorage.getItem('he_nr')
      }
    }).then(({data}) => {
      setLoading(false);
      togglePopup(false);
      NotificationManager.success('SMS er blevet sendt.');
    }).catch(error => {
      NotificationManager.error('Noget gik galt. SMS ikke sendt.');
      setLoading(false);
    });
  }

  return <>
    {loading ? <MyLoader /> : null}

    <button className="btn btn-link nav-link" onClick={() => openForm()}>Send SMS</button>
    
    <Dialog fullWidth={true} maxWidth="xs" onClose={() => togglePopup(false)} open={openPopup}>
      <DialogTitle onClose={() => togglePopup(false)}>
        Send SMS
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }} 
          onClick={() => togglePopup(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>                
      <DialogContent dividers>
        <div className="mb-3">
            <label className="form-label">Send til:</label>
            <select name="numbers" onChange={setFormInput} className="form-select">           
              <option value="">Vælg...</option>
              {phoneNumbers && phoneNumbers.map(phoneNumber => (
                <option value={phoneNumber.value} selected={inputValues &&  phoneNumber.value === inputValues.numbers ? true : false}>{phoneNumber.name}</option>
              ))}
              <option value="ANDET">Andet</option>
            </select>
            <input type={inputValues.numbers === 'ANDET' ? 'text' : 'hidden'} placeholder="Telefonnummer" value={inputValues.phone} onChange={setFormInput} name="phone" className="form-control mt-3"  />            
        </div>

        <div className="mb-3">
            <label className="form-label">SMS skabelon:</label>
            <select name="template" onChange={setFormInput} className="form-select">           
              <option value="">Vælg skabelon...</option>
              {templates && templates.map(template => (
                <option value={template.id} selected={inputValues &&  template.id == inputValues.template ? true : false}>{template.szTitle}</option>
              ))}
            </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Hvornår er du fremme?</label>
          <select name="minutes" onChange={setFormInput} className="form-select">
            <option value="">Vælg tid...</option>
            {[15, 30, 45, 60].map(minutes => (
              <option value={minutes} selected={inputValues && minutes === inputValues.minutes ? true : false}>{minutes} min</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">SMS tekst:</label>
          <textarea rows="5" placeholder="Skriv din besked her..." value={inputValues.message} className="form-control" name="message" onChange={setFormInput} />
        </div>
      </DialogContent>
      <DialogActions dividers>
          <button className="btn btn-secondary" onClick={() => togglePopup(false)}>Luk</button>
          <button className="btn btn-primary" onClick={handleSubmit}>Send</button>
      </DialogActions>
    </Dialog>
  </>
}

export default SendMessage;
