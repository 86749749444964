import { Link } from "react-router-dom";
import Layout from "../Layout";
import { useParams } from "react-router-dom";

export const TopMenu = () => {  
    const params = useParams();

    return <>
        <Layout />
        <nav className="navbar navbar-expand navbar-light case-top-menu bg-gray bg-gradient ps-2 pe-2 ps-lg-5 pe-lg-5 mb-3">
        <ul className="navbar-nav">
            <li className="nav-item active">
                <Link className="nav-link" to={`/start/${params.id}/dashboard`}>Dashboard</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/start/${params.id}/property/buildings`}>TR & EL Falles</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/start/${params.id}/tr-damages`}>TR Skader</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={`/start/${params.id}/el-damages`}>EL Skader</Link>
            </li>
        </ul>
        </nav>
    </>
  }
  
  export const LeftMenu = () => {
    return <>
        <nav className="navbar navbar-light case-left-menu d-none d-md-block bg-gray bg-gradient">
            <LeftMenuItems />
        </nav>
        <nav className="navbar navbar-expand navbar-light case-left-menu d-block d-md-none bg-gray bg-gradient">
            <LeftMenuItems />
        </nav>
    </>
  }

  const LeftMenuItems = () => {
    const params = useParams();

    return <ul className="navbar-nav">
        <li className="nav-item active">
            <Link className="nav-link" to={`/start/${params.id}/property/buildings`}>Bygning</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link" to={`/start/${params.id}/property/floors-and-rooms`}>Etager/rum</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link" to={`/start/${params.id}/property/materials`}>Materialer</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link" to={`/start/${params.id}/property/roof`}>Tag</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link" to={`/start/${params.id}/property/exceptions`}>Undtagelser</Link>
        </li>
    </ul>
  }