
function App() {
  return <>
    <div className="container">
      <h1>Page not found</h1>      
    </div>
  </>
}

export default App;
