import moment from 'moment';

export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

export function empty(value){
    if(value !== undefined && value !== null && value !== '' && value !== [] && value !== {} && value !== '0' && value !== 0 && value !== false){
        if(Array.isArray(value) && value.length === 0)
            return true;
        else
            return false;
    } else {
        return true;
    }
}

export function convertKeyToIndice(data){
    let ids = [];
    const dataKey = [];
    if (data) {
      ids = data.map((elemento) => {
        dataKey[elemento.id] = elemento;
        return elemento.id;
      });
      return ({ ids, dataKey });
    }
    return ({});
}


export function changeDateTimeFormat(datetime, format) {
     format = (format !== undefined && format !== null && format !== '' ? format : 'DD-MM-YYYY HH:mm');
     return moment(datetime).format(format) 
};

export function toHHMMSS(secs){
    return moment().startOf('day')
    .seconds(secs)
    .format('HH:mm:ss');
}

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkUserAllowedForBossTextModule(){
    return ['pooja@whiz-solutions.com','lalit@whiz-solutions.com','anand@whiz-solutions.com','sumit@whiz-solutions.com','rahul@whiz-solutions.com','jbn@domutech.dk','msp@domutech.dk','ini@domutech.dk','kha@domutech.dk', 'kko@domutech.dk','ang@domutech.dk'].includes(localStorage.getItem('szEmail'));
}

export function checkUserAllowedForEmployeeStats(){
    return ['pooja@whiz-solutions.com','lalit@whiz-solutions.com','anand@whiz-solutions.com','sumit@whiz-solutions.com','rahul@whiz-solutions.com','kko@domutech.dk','kk@hussyn.pro','mju@domutech.dk','cth@domutech.dk','kha@domutech.dk'].includes(localStorage.getItem('szEmail'));
}
export function getOlderDate(days, separator=''){
    let today = new Date();
    let newDate = new Date(new Date().setDate(today.getDate() - days));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

export function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

export function getCaseIcons(data){

    let ctr = 0;
    let icons = [];
    
    // check gallery status
    let iOnlineImages = parseInt(data['references'][0]['number_of_images']);
    if(iOnlineImages > 0){
        icons[ctr] = 'galleryUploaded.png';
    } else {
        icons[ctr] = 'galleryEmpty.png';
    }
    ctr++;
    
    // check VTR status
    if(!empty(data['references'][0]['vtr_data'])){
        icons[ctr] = 'vtrEnabled.png';
    } else {
        icons[ctr] = 'vtrDisabled.png';
    }
    ctr++;
    
    // check key fetch status
    if(!empty(data['waypoint_address'])){
        if(!empty(data['waypoint_return'])){
            icons[ctr] = 'keyReturnGreen.png';
        } else {
            icons[ctr] = 'keyFetchGreen.png';
        }
    } else {
        icons[ctr] = 'keyReturnGray.png';
    }
    ctr++;
    
    // check key placement status
    if(!empty(data['key_placement_text'])){
        icons[ctr] = 'keyPlacementEnabled.png';
    } else {
        icons[ctr] = 'keyPlacementDisabled.png';
    }
    ctr++;        
    
    return <div className="case-icons">
        {icons.map(icon => (
            <img src={'/images/icons/'+icon} />
        ))}
    </div>
}

export function getDayName(dateStr)
{
    var date = new Date(dateStr);
    return date.toLocaleDateString('da-DK', { weekday: 'long' });        
}

export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
}

export function logout(){
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('he_nr');
    localStorage.removeItem('logged_in');
    window.location.href = process.env.REACT_APP_BASE_URL + '/login?logout=true';
  }