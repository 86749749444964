import React from "react";
import DataProvider from '../DataProvider';
import { empty, changeDateTimeFormat, getCaseIcons, getDayName, camelize } from "../helpers/functions";
import MyLoader from '../helpers/MyLoader';
import MenuIcon from '@mui/icons-material/MoreVert';
import Messages from "./SeeMessages";
import SendMessage from "./SendSMS";
import { Link } from "react-router-dom";
import Layout from "../Layout";

const CaseList = (props) => {
  const loadDtata = true;
  const he_nr = localStorage.getItem('he_nr');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [days, setDays] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    DataProvider('CUSTOM', 'boss-app/getConsultantCases', {
      method:'GET',
      data:{he_nr:he_nr}
    }).then(({data}) => {
      setData(data.content);
      getDates(data.content);
      setLoading(false);
    })
  }, [he_nr]);

  const getDates = (data) => {
    let lastDate = '';
    let allDays = [];
    !empty(data) && data.map(row => {
      let currentDate = changeDateTimeFormat(row.inspection_time, 'DD.MM.YYYY');
      
      if(lastDate !== currentDate){
        allDays.push({date:currentDate, full_date:row.inspection_time});
      }
      lastDate = currentDate;
    })
    setDays(allDays);
  }

  return <>
    {loading ? <MyLoader /> : null}   
    <Layout />
    <div className="container margin-bottom">      
      <h1>Sagslist</h1>
    </div>

    <div className="mb-5">
      {!empty(days) && days.map(day => (
      <div className="bg-light mt-5 p-3 position-relative text-black-50 fst-normal">
        <div style={{left:'25%'}} className="date-title p-3 ps-2 pe-2 position-absolute bg-light top-0 translate-middle fw-bold"><span className="position-relative" style={{top:'-15px'}}>{camelize(getDayName(day.full_date))} d.{day.date}</span></div>
        <div className="container margin-bottom position-relative">      
          {!empty(data) && data.map((row, index) => {
            if(changeDateTimeFormat(row.inspection_time, 'DD.MM.YYYY') === day.date ) {
              return <div className="row case-item">
                <div className="col-2">
                  <div className="thumbnail">
                    <img className="rounded img-fluid" src={(!empty(row.cover_image) ? row.cover_image : row.satellite_image.thumbnail)} />
                  </div>
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Link to={`/start/50793/dashboard`}>{row.address}</Link>
                    </div>
                    <div className="col-12 col-sm-6">
                      Kl.: {changeDateTimeFormat(row.inspection_time, 'HH:mm')}
                      {!empty(row.inspection_end_time) ? ' - ' + changeDateTimeFormat(row.inspection_end_time, 'HH:mm') : ''}<br />
                      {row.references[0].report}
                      {getCaseIcons(row)}
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <Messages messages={row.messages} id={row.id} />
                </div>
                <div className="col-1">
                  <button className="btn btn-link"  data-bs-toggle="collapse" data-bs-target={`#listMenu${index}`} aria-controls={`listMenu${index}`} aria-expanded="false" aria-label="Toggle list action">
                    <MenuIcon />
                  </button>
                  <div className="navbar collapse navbar-collapse p-1 bg-dark bg-gradient" id={`listMenu${index}`} style={{width:'max-content'}}>
                    <ul className="navbar-nav list-item">
                        <li className="nav-item border-bottom ps-2 pe-2">
                            <a className="nav-link" target="_blank" href={`https://booking-boligeftersyn.dk/adm/sag_admin.php?id=${row.case_number}`}>Se case på BOSS online</a>
                        </li>
                        <li className="nav-item ps-2 pe-2">
                          <SendMessage data={row} />
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            } else {
              return null;
            }
          })}
        </div>
      </div>
      ))}

    </div>
  </>
}

export default CaseList;
