import React from 'react';
import queryString from 'query-string';
import MyLoader from './helpers/MyLoader';
import { empty, getRandomString, getCurrentDate, logout } from './helpers/functions';
import { Box, Link, Card, CardContent} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useLocation} from 'react-router-dom';
import DataProvider from './DataProvider';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles({
    domLogoDiv: {
        textAlign: 'center',
        padding: '50px 20px',
        backgroundColor: '#D4794B',
        color: 'white',
        marginBottom: "10px",
        maxWidth: '350px'
    },
    smallLogo: {
        fontSize: 20,
        fontWeight: 'normal'
    },
    largeLogo: {
        fontSize: 44,
        fontWeight: 'bold'
    },
    wp100: {
        width: '100%'
    },
    mb20: {
        marginBottom: "20px"
    },
    mt20: {
        marginTop: "20px"
    },
    card: {
        maxWidth: '350px',
    },
    cardBox: {
        padding: '0',
        paddingBottom: '0 !important'
    },
    page: {
        backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',        
    }
});

const MyLogin = (props) => {
    const location = useLocation();
    const request_key = getRandomString(30);
    const classes = useStyles();
    const [isLoader, setLoader] = React.useState(false);
    const [askHeNr, setAskHeNr] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [he_nr, setHeNr] = React.useState('');
    const url_query = queryString.parse(location.search);

    React.useEffect(() => {
        let logoutUser = localStorage.getItem('logout');
        if(!empty(logoutUser)){
            setLoader(true);
            localStorage.removeItem('logout');
            window.location.href = process.env.REACT_APP_LOCAL_AUTH_API_URL + '/authorize/logout?user=' + logoutUser + '&from=boss_field_online';
        } else if(!askHeNr) {
            setLoader(true);
            let loginUser = localStorage.getItem('logged_in');
            if(!empty(loginUser)){
                let he_nr = localStorage.getItem('he_nr');
                if(!empty(he_nr)){
                    if(url_query.redirect !== undefined && url_query.redirect !== '' && url_query.redirect !== null){
                        window.location.href = process.env.REACT_APP_BASE_URL +"/"+url_query.redirect;
                    } else {
                        window.location.href = process.env.REACT_APP_BASE_URL + "/case-list";
                    }
                    
                } else {
                    setAskHeNr(true);
                    setLoader(false);
                }
            } else if(!empty(url_query.restricted)){  
                setLoader(false);
            } else if(!empty(url_query.error)){ 
                setLoader(false);
            } else if (url_query.szEmail && url_query.szToken){
                localStorage.setItem('email', url_query.szEmail);
                localStorage.setItem('token', url_query.szToken);
                localStorage.setItem('last_login_on', getCurrentDate('-'));
                
                if(!empty(url_query.he_nr)){
                    localStorage.setItem('he_nr', url_query.he_nr);
                    localStorage.setItem('logged_in', 'true');
                    if(url_query.redirect !== undefined && url_query.redirect !== '' && url_query.redirect !== null){
                        window.location.href = process.env.REACT_APP_BASE_URL +"/"+url_query.redirect;
                    } else {
                        window.location.href = process.env.REACT_APP_BASE_URL + "/case-list";
                    }
                } else {
                    setAskHeNr(true);
                    setLoader(false);
                }
            } else {
                window.location.href = process.env.REACT_APP_LOCAL_AUTH_API_URL + '/authorize/login?state=' + request_key + '&from=boss_field_online' + (!empty(url_query.from) ? '&return_uri=' + url_query.from : '');
            }
        }
    }, [url_query]);

    const fetchCases = () => {
        if(!empty(he_nr)){        
            setLoader(true);
            DataProvider('CUSTOM', 'boss-app/getConsultantCases', {
                method:'GET',
                data:{he_nr:he_nr}
            }).then(({data}) => {            
                setLoader(false);
                localStorage.setItem('he_nr', he_nr);
                localStorage.setItem('logged_in', 'true');
                window.location.href = process.env.REACT_APP_BASE_URL + '/case-list';
            }).catch(error => {
                setErrorMessage(error.message);
                NotificationManager.error(error.message, "Error");
                setLoader(false);
            });
        } else {
            setErrorMessage('HE Nr is required.');
        }
    }

    return (
        <Box
            className={classes.page}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            {isLoader ? <MyLoader message="Please wait..." /> : null}
            {(!isLoader && (askHeNr || !empty(url_query.restricted) || !empty(url_query.error))) || (isLoader && askHeNr) ?
            <Card className={classes.card}>
                <CardContent className={classes.cardBox}>
                    <Box className={classes.domLogoDiv}>
                        <span className={classes.largeLogo}>
                            <sup><span className={classes.smallLogo} >BOSS Feild</span></sup>
                            Online
                        </span>
                    </Box>

                    <Box padding="20px">
                        {askHeNr ? <>
                        <div className="alert alert-info mb-3">
                            Ingen konsulentkonto er knyttet til din konto, indtast venligst et gyldigt HE NR nedenfor og hent sager til behandling.
                        </div>

                        <Box className="form-group mb-3 text-center">
                            <label className="form-label">Indtast dit HE nummer</label>
                            <input className="form-input" value={he_nr} onChange={
                                (event) => setHeNr(event.target.value)} onKeyDown={(event) => {
                                  if(event.key === 'Enter')
                                    fetchCases(); 
                                  else 
                                    setHeNr(event.target.value)
                                }}
                            />
                        </Box>

                        {!empty(errorMessage) ? <div className="alert alert-danger mb-3 p-1">{errorMessage}</div> : null}

                        <Box className="form-group">
                            <button style={{width:'100%'}} className="btn btn-success btn-block" onClick={fetchCases}>Hent Sager</button>
                            <hr />
                            <button style={{width:'100%'}} className="btn btn-primary btn-block" onClick={logout}>Log ind med en anden konto</button>
                        </Box>
                        </> : <>
                        <Box className={classes.mb20}>
                            <Link  href="#" onClick={(e) => {e.preventDefault();window.location.href = process.env.REACT_APP_LOCAL_AUTH_API_URL + '/authorize/login/google-oauth2?state=' + request_key + '&from=boss_field_online' + (!empty(location.state) && !empty(location.state.nextPathname) ? '&return_uri=' + location.state.nextPathname : '')}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="300" height="42" viewBox="0 0 300 42">
                                    <g id="Group_9" data-name="Group 9" transform="translate(-827 -579)">
                                        <g id="Rectangle_9" data-name="Rectangle 9" transform="translate(827 579)" fill="#fff" stroke="#b9b9b9" stroke-width="1">
                                        <rect width="300" height="42" rx="21" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="299" height="41" rx="20.5" fill="none"/>
                                        </g>
                                        <text id="Fortsæt_med_google_" data-name="Fortsæt med google " transform="translate(893 606)" fill="#707070" font-size="18"><tspan x="0" y="0">Fortsæt med google</tspan></text>
                                        <g id="logo_googleg_48dp" transform="translate(858.478 590.144)">
                                        <path id="Shape" d="M18.141,9.311a10.941,10.941,0,0,0-.173-1.948H9v3.683h5.125a4.38,4.38,0,0,1-1.9,2.874V16.31H15.3a9.287,9.287,0,0,0,2.839-7Z" transform="translate(0.522 0.427)" fill="#4285f4" fill-rule="evenodd"/>
                                        <path id="Shape-2" data-name="Shape" d="M9.466,18.423a9.091,9.091,0,0,0,6.3-2.307l-3.077-2.389A5.745,5.745,0,0,1,4.138,10.71H.957v2.467a9.518,9.518,0,0,0,8.509,5.246Z" transform="translate(0.056 0.621)" fill="#34a853" fill-rule="evenodd"/>
                                        <path id="Shape-3" data-name="Shape" d="M4.194,11.044a5.629,5.629,0,0,1,0-3.618V4.958H1.013a9.534,9.534,0,0,0,0,8.552l3.181-2.467Z" transform="translate(0 0.288)" fill="#fbbc05" fill-rule="evenodd"/>
                                        <path id="Shape-4" data-name="Shape" d="M9.466,3.787a5.144,5.144,0,0,1,3.64,1.424L15.838,2.48A9.151,9.151,0,0,0,9.466,0,9.518,9.518,0,0,0,.957,5.246L4.138,7.713A5.675,5.675,0,0,1,9.466,3.787Z" transform="translate(0.056)" fill="#ea4335" fill-rule="evenodd"/>
                                        <path id="Shape-5" data-name="Shape" d="M0,0H19.044V19.044H0Z" fill="none" fill-rule="evenodd"/>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                        </Box>

                        <Box className={classes.mb20}>
                            <Link href="#" onClick={(e) => {e.preventDefault();window.location.href = process.env.REACT_APP_LOCAL_AUTH_API_URL + '/authorize/login?state=' + request_key + '&from=boss_field_online' + (!empty(location.state) && !empty(location.state.nextPathname) ? '&return_uri=' + location.state.nextPathname : '')}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="300" height="45" viewBox="0 0 300 45">
                                    <g id="Group_12" data-name="Group 12" transform="translate(-827 -759)">
                                        <rect id="Rectangle_10" data-name="Rectangle 10" width="300" height="45" rx="22.5" transform="translate(827 759)" fill="#1da8ab"/>
                                        <text id="Log_ind" data-name="Log ind" transform="translate(930 788)" fill="#fff" font-size="18"><tspan x="0" y="0">Log ind</tspan></text>
                                    </g>
                                </svg>
                            </Link>
                        </Box>

                        {(!empty(url_query.restricted) ?
                        <Box className="text-danger mt20" display="block" maxWidth="100%" fontWeight={600}>
                            Din konto afventer godkendelse, vi vil undersøge dette så hurtigt som muligt.
                        </Box> : null
                        )}

                        {(!empty(url_query.error) ?
                        <Box className="text-danger mt20" display="block" maxWidth="100%">
                            {url_query.error}
                        </Box> : null
                        )}
                        </>}
                    </Box>
                </CardContent>
            </Card> : null}
        </Box>
    )
}

export default MyLogin;
