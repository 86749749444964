import { stringify } from 'query-string';

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
    let apiUrl = (resource.indexOf('phrases') !== -1 ? process.env.REACT_APP_PHRASE_API_URL : process.env.REACT_APP_API_URL + '/' + (resource === 'supplier-templates' ? 'suppliers/templates' : resource));
    let subResource = (resource.indexOf('/') !== -1 ? '/' + resource.split('/')[1] : '');

    let url = '';
    const options = {
        headers: new Headers({
            Accept: 'application/json',
        }),
    };
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `${token}`);
    if (type === `UPDATE` || type === `CREATE`) {
        url = (type === `UPDATE` ? `${apiUrl}/edit/${params.id}` : `${apiUrl}/add`);
        options.method = 'POST';

        // get uploaded files local name, type & size if any uploaded
        if (params.data.files !== undefined) {
            params.data.files = params.data.files.map((file, index) => {
                if (file.file !== undefined || file.rawFile !== undefined) {
                    let t_file = {};
                    Object.keys(file).map(key => {
                        if (key === 'file' || key === 'rawFile') {
                            if (key === 'rawFile') {
                                t_file['name'] = file.rawFile.name;
                                t_file['type'] = file.rawFile.type;
                                t_file['size'] = file.rawFile.size;
                            }
                            else {
                                t_file['name'] = file.file.rawFile.name;
                                t_file['type'] = file.file.rawFile.type;
                                t_file['size'] = file.file.rawFile.size;
                            }
                        }
                        t_file[key] = file[key];
                        return true;
                    });
                    return t_file;
                } else {
                    return Object.assign({}, file, {});
                }
            });
        }
        options.body = JSON.stringify(params.data);
    } else {
        switch (type) {
            case `GET_LIST`: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        perPage,
                    ]),
                    filter: JSON.stringify(params.filter),
                };
                options.method = 'GET';
                url = `${apiUrl}/getList?${stringify(query)}`;
                break;
            }
            case `GET_ONE`:
                options.method = 'GET';
                url = `${apiUrl}/getDetails/${params.id}`;
                break;
            case `GET_MANY`: {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/getSelectedDetails/?${stringify(query)}`;
                break;
            }
            case `GET_MANY_REFERENCE`: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        perPage,
                    ]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                url = `${apiUrl}/getManyReference/?${stringify(query)}`;
                break;
            }
            case `UPDATE_MANY`:
                url = `${apiUrl}`;
                options.method = 'GET';
                if (resource === "theme-management/assign-theme" || resource === "package-management/assign-package") {
                    let themeid = localStorage.getItem("SelectedItem");
                    params.data = themeid;
                }
                const query = {
                    arRecord: JSON.stringify({ id: params.ids }),
                    arUpdateBy: JSON.stringify({ id: [params.data] }),
                }
                url = `${apiUrl}?${stringify(query)}`;
                break;
            case `DELETE`:
                url = `${apiUrl}/delete/${params.id}`;
                break;
            case 'CUSTOM':
                url = `${apiUrl}`;
                options.method = (params.method !== undefined ? params.method : 'GET');
                 if(params.data !== undefined && Object.keys(params.data).length > 0){
                    if (params.method === 'GET') {
                        var queryString = Object.keys(params.data).map(key => key + '=' + params.data[key]).join('&');
                        url += '?' + queryString;
                    }
                    else {
                        options.body = JSON.stringify(params.data);
                    }
                }
                break;
            case 'CUSTOMDEMO':
                url = `${apiUrl}/getRealtorSelectedDetails`;
                options.body = JSON.stringify(params.data);
                options.method = 'GET';
                break;
            default:
                throw new Error(`Unsupported Data Provider request type ${type}`);
        }
    }
    return fetchData(url, options, type, params, resource, subResource);
}

function fetchData(url, options, type, params, resource, subResource) {
    if (resource.indexOf('phrases') !== -1 && subResource !== '') {
        url = (url.indexOf('?') !== -1 ? url.replace('?', subResource + '?') : url + subResource);
    }

    let result, status, headers;
    return fetch(url, options)
        .then((response) => {
            if(response.status === 200)
                return response.json()
            else
                return Promise.reject(response);
        }).then((json) => {            
            switch (type) {
                case `GET_LIST`:
                    if (!headers.has('content-range')) {
                        throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
                    }
                    return {
                        data: json,
                        total: parseInt(
                            headers
                                .get('content-range')
                                .split('/')
                                .pop(),
                            10
                        ),
                    };
                case `CREATE`:
                    return { data: { ...params.data, id: json.id } };
                default:
                    return { data: json };
            }
        })
        .catch(response => {
            return response.json().then(json => {
                let description = json.description;
                const error = json.error;
                if (error !== undefined) {
                    if (error === '_TOKEN_EXPIRED_') {
                        localStorage.removeItem('szEmail');
                        localStorage.removeItem('token');
                        localStorage.removeItem('loginDate');
                        localStorage.removeItem('isAutoLoggedIn');
                        localStorage.removeItem('idAdmin');
                        localStorage.removeItem('szKeyName');
                        window.location.href = process.env.REACT_APP_BASE_URL + '/login';
                        throw new Error('Login Session has been expired, Please do Login again !');
                    } else {
                        throw new Error(error);
                    }
                } else if(description !== undefined){
                    throw new Error(description);
                } else {
                    throw new Error('Something went wrong, please try again later.');
                }
            });
        }).catch(error => {
            throw new Error(error)
        });
}