import React from "react";
import { useParams } from 'react-router';
import DataProvider from '../DataProvider';
import { empty } from "../helpers/functions";
import MyLoader from '../helpers/MyLoader';
import MenuIcon from '@mui/icons-material/MoreVert';
import { TopMenu, LeftMenu } from "./Menus";
import { useEffect } from "react";

const StartCase = (props) => {
  const params = useParams();
  const he_nr = localStorage.getItem('he_nr');
  const [loading, setLoading] = React.useState(false);
  const [caseDetails, setCaseDetails] = React.useState({});

  React.useEffect(() => {
    setLoading(true);
    DataProvider('CUSTOM', 'boss-online/getCaseDetails', {
      method:'GET',
      data:{ref_id:params.id}
    }).then(({data}) => {
      setCaseDetails(data.content);
      setLoading(false);
    })
  }, [params]);
 
  return <>
    {loading ? <MyLoader /> : null}
    <TopMenu />
    {!empty(caseDetails) ? 
    <div className="container margin-bottom">
      <div className="mb-3">
        <span className="badge rounded-pill bg-secondary me-3">{caseDetails.address}</span>  
        <span className="badge rounded-pill bg-secondary me-3">{caseDetails.inspection_datetime && caseDetails.inspection_datetime.split(' ')[0]}</span> 
        <span className="badge rounded-pill bg-secondary me-3">Kl. {caseDetails.inspection_datetime && caseDetails.inspection_datetime.split(' ')[1]}</span> 
      </div>

      <div className="row">
        <div className="col-md-3">
            <LeftMenu />
        </div>
        <div className="col-md-9">
            Page content goes here!
        </div>
      </div>
    </div> : null}
  </>
}

export default StartCase;
