import { Outlet, Link } from "react-router-dom";
import { logout } from "./helpers/functions";

const Layout = () => {  

  return <>      
    <nav className="navbar navbar-expand-lg navbar-light sticky-top topbar">
      <div className="col-sm-4 d-none d-sm-block"></div>

      <div className="col-sm-4 text-center d-none d-sm-block">
        <div className="navbar-header">
          <Link className="navbar-brand" to="/case-list">
              <img alt="Brand" src="/images/logo.png" />
          </Link>
        </div>
      </div>

      <div className="col-12 col-sm-4 pe-2 ps-2">
        <div className="d-block d-sm-none">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopLeft" aria-controls="navbarTopLeft" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="collapse navbar-collapse p-2 bg-dark bg-gradient" id="navbarTopLeft">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="login?logout=true" onClick={logout}>Logout</Link>
                </li>
            </ul>
          </div>
        </div>

        <div className="d-none d-sm-block text-end">
          <ul className="navbar-nav flex-row-reverse">            
            <li className="nav-item">
              <Link className="nav-link" to="login?logout=true" onClick={logout}>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>    
    <Outlet />
  </>
};

export default Layout;
