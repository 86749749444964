import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes , Route } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-notifications/lib/notifications.css';

import Layout from './Layout';
import CaseList  from './case_list';
import NoPage from './404';
import Login from './Login';
import StartCase from './start';

import { PrivateRoute } from './PrivateRoute';

export default function App(props) {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="case-list" element={
            <PrivateRoute from="case-list">
              <CaseList load={true} />
            </PrivateRoute>
          } />
          <Route path="start/:id" element={
            <PrivateRoute from="start/:id">
              <StartCase {...props} />
            </PrivateRoute>
          } />
          <Route path="start/:id/:page" element={
            <PrivateRoute from="start/:id/:page">
              <StartCase {...props} />
            </PrivateRoute>
          } />
          <Route path="start/:id/:page/:subpage" element={
            <PrivateRoute from="start/:id/:page/:subpage">
              <StartCase {...props} />
            </PrivateRoute>
          } />
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotificationContainer />
    <App />
  </React.StrictMode>
);
